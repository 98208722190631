.main-nav {
  @include clearfix;

  &:after {
    content: "";
    display: block;
    height: 2px;
    background-color: $light-brown;
  }

  .menu-item {
    float: left;
    @include omega(3n);
    display: block;
    width: 33.3%;
    height: 90px;
    padding: 20px 0;
    text-align: center;
    border-right: $nav-item-border;
    border-bottom: $nav-item-border;

    a {
      font-weight: 700;
      color: $light-gray;
      text-transform: uppercase;

      .icon-caret-down {
        margin-left: 5px;
        position: relative;
        top: 2px;
      }
    }

    &:nth-child(n+4) {
      border-bottom: none;
    }

    &:nth-child(3n) {
      border-right: none;
    }

    &.current-menu-item,
    &.current-page-ancestor {
      a {
        color: white;
      }
    }
  }

  @include media($large-screen-up) {
    max-width: 890px;
    margin: 0 auto;

    &:after {
      content: none;
    }

    .menu-item {
      width: 110px;
      padding: 0;
      height: 60px;
      border-left: $nav-item-border;
      border-right: $nav-item-border;
      border-bottom: none;
      margin-right: -1px;
      margin-top: 14px;
      margin-bottom: 14px;
      @include omega-reset(3n);

      &:nth-child(3n) {
        margin-right: 0;
        border-right: $nav-item-border;
      }

      &.menu-item-has-children {
        margin-right: 233px;
      }

      &:hover {
        > a {
          color: white;
        }
      }
    }
  }

  @include media(1280px) {
    max-width: 1028px;

    .menu-item {
      width: 120px;

      &.menu-item-has-children {
        margin-right: 310px;
      }
    }
  }

  .ie8 & {
    &:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }

    .menu-item {
      width: 107px;
    }
  }
}

.submenu {
  background-color: white;
  border: $subnav-border;
  position: absolute;
  top: 90px;
  right: 17px;
  width: 190px;
  z-index: 10;

  &:before {
    content: "";
    @include triangle(20px, white, up);
    @include position(absolute, -10px 30px null null);
  }

  .submenu-item {
    text-align: center;
    border-bottom: $nav-item-border;

    a {
      display: block;
      padding: 12px 0;
      color: $light-brown;
      text-transform: uppercase;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  @include media($medium-screen-up) {
    right: 35%;
  }

  @include media($large-screen-up) {
    top: 70px;
    right: 17px;
  }
}

.nav-icon-holder {
  height: 37px;
}

.menu-item-has-children {
  position: relative;
}

.nav-is-visible {
  i {
    color: white;
  }
}
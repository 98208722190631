.actions-bar {
  color: $light-gray;
  padding: 20px 15px 35px;
  background-image: url("img/actions-bar-bg.png");
  position: relative;
  z-index: 2;
  margin-bottom: -16px;
  @include clearfix;

  @include media($large-screen-up) {
    background-image: none;
    background-color: rgba($dark-brown, .7);
    height: 56px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: -101px;
    margin-bottom: 4px;

    .button--new-message {
      display: inline-block;
      float: right;
      position: relative;
      top: -8px;
      margin-bottom: 0;
    }

    &:before {
      content: '';
      display: block;
      height: 22px;
      background-image: url('img/actionsbar-lower-brush-light.png');
      background-size: contain;
      background-repeat: repeat-x;
      @include position(absolute, null 0 -22px 0);
    }

    .no-rgba & {
      background-image: url('img/norgba-actionsbar-bg-light.png');
    }

    .ie8 & {
      height: 18px;
    }
  }
}

.filters {
  font-size: 0.1px;
  text-align: justify;

  &:after {
    content: "";
    width: 100%;
    display: inline-block;
  }

  .filter {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    zoom: 1;
    *display: inline;
    padding-right: 20px;
    background: url("img/dropdown-arrow.png") 110px 9px no-repeat;
  }

  @include media($large-screen-up) {
    display: inline-block;

    .filter {
      margin-right: 20px;
    }
  }

  .ie8 & {
    overflow: hidden;
    width: 400px;

    .filter {
      float: left;
    }
  }
}
.modal-overlay {
	@include position(fixed, 0 0 0 0);
	background-color: rgba(black, .5);
	z-index: 10;
	visibility: hidden;
	opacity: 0;
	@include transition(all 0.3s);
}

.modal {
	@include position(fixed, 50% null null 50%);
  width: 50%;
  max-width: 846px;
  min-width: 320px;
  height: auto;
  z-index: 20;
  visibility: hidden;
  transform: translateX(-50%) translateY(-50%);
}

.modal__inner {
	background-color: white;
  padding: 70px;
  position: relative;
  margin: 0 auto;
  @include transform(scale(0.7));
  opacity: 0;
  @include transition(all 0.3s);

  .comment-form__title {
  	font-family: $heading-font-family;
  	font-size: 21px;
  	font-weight: 700;
  	color: $dark-brown;
  	text-align: center;
  	position: relative;

  	span {
  		@include dashes(600px, 1px, $dark-brown);
  	}

  	@include media($large-screen-up) {
  		font-size: 36px;
  	}

  	&:before {
		  content: '';
		  display: block;
		  background-image: url("img/title-spots-white.png");
		  background-position: 50%;
		  position: absolute;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  left: 0;
		  z-index: 1;
  	}
  }

  .comment-form {
  	color: $dark-brown;
  	padding: 0;
  	overflow: hidden;

  	input[type='text'],
  	textarea {
  		background-color: #F3F3F3;
  		border-color: #E8E6E6;

  		&.error {
  			border-color: red;
  		}
  	}
  }

  &:before,
  &:after {
  	content: '';
  	display: block;
  	@include size(100% 41px);
  	@include position(absolute, null 0 null 0);
  	background-size: contain;
  	background-repeat: no-repeat;
  }

  &:before {
  	top: -16px;
  	background-image: url('img/modal-upper-brush.png');
  }

  &:after {
  	bottom: -16px;
  	background-image: url('img/modal-lower-brush.png');
  }
}

.modal__close {
	@include position(absolute, 30px 30px null null);
}

.is-visible {
	&.modal {
		visibility: visible;

		.modal__inner {
			@include transform(scale(1));
			opacity: 1;
		}
	}

	~ .modal-overlay {
		opacity: 1;
		visibility: visible;
	}
}
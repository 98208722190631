// articles list
.entry-header {
  position: relative;
  padding: 20px 15px 0;
}

.entry-title {
  width: 75%;
  font-size: 21px;
  margin-bottom: 10px;

  @include media($medium-screen-up) {
    font-size: 28px;
  }

  @include media($large-screen-up) {
    font-size: 36px;
  }
}

.entry-author-avatar {
  width: 50px;
  position: absolute;
  top: 20px;
  right: 15px;

  @include media($large-screen-up) {
    width: 60px;
    right: 15px;
  }
}

.entry-meta {
  li {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    color: $medium-gray;
    a {
      color: $medium-gray;
    }
    &.entry-meta__author {
      display: none;

      @include media($medium-screen-up) {
        display: inline-block;
      }
    }
  }

  .ie8 & {
    overflow: hidden;
    li {
      display: inline;
    }
  }
}

.entry-content {
  padding-bottom: 20px;

  figure,
  .media-holder {
    margin: 20px 15px;
  }

  .media-holder {
    @include media($medium-screen-up) {
      height: 370px;
    }
  }

  figure {
    .image-holder {
      position: relative;
      height: 124px;
      overflow: hidden;
      transform-style: preserve-3d;

      img {
        position: relative;
        opacity: 1 !important;
      }

      @include media($medium-screen-up) {
        height: 290px;
      }
    }
  }
}

.entry-content--about {
  > blockquote {
    padding: 0 35px;
    text-align: center;
  }
}

.header-image {
  margin-bottom: 24px;
}

.header-image__holder {
  position: relative;
  height: 124px;
  overflow: hidden;
  transform-style: preserve-3d;

  img {
    position: relative;
    opacity: 1 !important;
  }

  @include media($medium-screen-up) {
    height: 360px;
  }

  @include media($large-screen-up) {
    height: 560px;
  }
}

.entry-content__inner {
  padding: 0 15px;

  figure {
    margin: 20px 0;
  }
}

.hentry {
  border-bottom: 1px dashed $light-gray;

  &:last-child {
    border-bottom: none;
  }

  &.post {
    .entry-header {
      color: white;
      background-color: $dark-brown;
      padding-top: 120px;
      padding-bottom: 20px;

      @include media($large-screen-up) {
        padding-top: 150px;
        padding-bottom: 40px;
      }
    }

    .entry-content {
      figure,
      .media-holder {
        margin: 0 0 20px;
      }

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 24px;
      }

      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 20px;
      }

      h4 {
        font-size: 16px;
      }

      h5 {
        font-size: 14px;
      }

      h6 {
        font-size: 12px;
      }

      ul {
        @extend %default-ul;
      }

      ol {
        @extend %default-ol;
      }
    }

    .entry-content__inner {
      max-width: 690px;
      margin: 0 auto;
    }
  }

  &--no-border {
    border-bottom: none;

    .entry-header {
      padding-top: 0;
    }

    .entry-author-avatar {
      width: 70px;
      top: -10px;
    }

    .entry-meta {
      margin-bottom: 10px;
    }

    .entry-content {
      padding-bottom: 0;
    }
  }
}

.article-group {
  @include media($large-screen-up) {
    padding-left: 55px;
    padding-right: 55px;
  }
}

.content--dotd {
  .entry-title {
    color: $green;
  }
}

.content--htfu {
  .entry-title {
    color: $purple;
  }
}

// highlighted hentry
.hentry--highlighted {
  color: #d1cecd;
  text-align: center;
  margin: 20px 15px;
  background: url(img/entry-highlighted-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-bottom: none;

  .entry-title {
    width: 100%;
    margin-top: 20px;
  }

  @include media($large-screen-up) {
    background: url('img/entry-highlighted-bg-large.jpg');
    background-size: cover;
  }

  .ie8 & {
    .entry-meta {
      padding-left: 150px;
    }
  }
}

.entry-title--dotd {
  color: $green;
}

.hentry--highlighted__entry-header {
  margin-top: 60px;
}

.hentry--highlighted__logo {
  width: 117px;
  margin: 0 auto;
  img {
    margin-top: -40px;
  }
}

.hentry--highlighted__avatar {
  width: 142px;
  margin: 10px auto;
}

.intro-title {
  font-weight: 700;
  color: $light-brown;
  text-transform: uppercase;
  padding: 30px 15px 0;
  margin-bottom: 0;
}

// dotd
.content--dotd,
.content--htfu {
  .entry-author-avatar {
    img {
      position: absolute;
      display: none;

      &.show-avatar {
        display: block;
      }
    }
  }

  @include media($large-screen-up) {
    padding-left: 55px;
    padding-right: 55px;

    .hentry {
      position: relative;
      border-bottom: 1px dashed $light-gray;
      padding: 30px 180px 30px 80px;

      &:last-child {
        border-bottom: none;
      }
    }

    .article__entry {
      position: absolute;
      left: -15px;
      margin: 10px 0;

      span {
        &:before,
        &:after {
          content: none;
        }
      }
    }

    .entry-title {
      width: 100%;
    }

    .entry-author-avatar {
      width: 100px;
      right: -160px;
    }
  }
}
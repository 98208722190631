// Typography
$base-font-family: "proxima-nova", sans-serif;
$heading-font-family: "schoolbook-web";

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$green: #8aa147;
$purple: #b890a4;
$blue: #477dca;

$dark-gray: #333333;
$medium-gray: #8c8482;
$light-gray: #bab5b4;
$lightest-gray: #D1CECD;

$light-brown: #b4532f;
$medium-brown: #473b38;
$dark-brown: #190a06;

// Font Colors
$base-background-color: white;
$base-font-color: $dark-brown;
$action-color: $light-brown;

// Background Colors
$dark-background: $dark-brown;

// Border
$nav-item-border: 1px dashed $medium-gray;
$subnav-border: 1px solid #e8e6e6;
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Shadows
$featured-text-shadow: 0 0 16px $medium-gray;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(black, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);
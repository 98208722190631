.tile {
	height: 140px;
	text-align: center;
	border-bottom: 1px dashed $dark-brown;
	overflow: hidden;

	a {
		display: block;
		height: 100%;
		padding: 25px 20px;
		position: relative;
		@include transition(padding-top 0.3s);
	}

	@include media($medium-screen-up) {
		width: 50%;
		height: 160px;
		float: left;
		border-right: 1px dashed $dark-brown;
		@include omega(2n);

		&:nth-child(2n) {
			border-right: none;
		}
	}

	@include media($large-screen-up) {
		width: 25%;
		height: 240px;
		float: left;

		&:nth-child(2n+1) {
			clear: none;
		}

		@include omega(4n);

		&:not(:nth-child(4n)) {
			border-right: 1px dashed $dark-brown;
		}
	}

	.ie8 & {
		width: 24.9%;
	}
}

.tile__image-holder {
	opacity: 0;
	@include position(absolute, 100% 0 0 0);
	@include transition(opacity 0.5s, top 0.3s 0.05s);
}

.tile:hover {
	.tile__image-holder {
		opacity: 1;
		top: 0;

		&:before {
			content: '';
			display: block;
			@include position(absolute, 0 0 0 0);
			background-color: rgba(black, .2);
		}
	}

	a {
		padding-top: 50px;
	}

	.tile__pretitle {
		span {
			color: white;

			&:before,
			&:after {
				background-color: white;
			}
		}
	}
}

.tile__copy {
	position: relative;
	z-index: 2;
}

.tile__pretitle {
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 10px;

	span {
		@include dashes(40px, 1px, $dark-brown);
	}

	@include media($large-screen-up) {
		font-size: 18px;
	}
}

.tile__title {
	font-size: 24px;
	color: white;
	margin-bottom: 0;

	@include media($medium-screen-up) {
		font-size: 30px;
	}

	@include media($large-screen-up) {
		font-size: 36px;
	}
}
@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?wj3o2p");
  src: url("fonts/icomoon.eot?#iefixwj3o2p") format("embedded-opentype"), url("fonts/icomoon.woff?wj3o2p") format("woff"), url("fonts/icomoon.ttf?wj3o2p") format("truetype"), url("fonts/icomoon.svg?wj3o2p#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-message:before {
  content: "\e600";
}

.icon-chevron-left:before {
  content: "\f053";
}

.icon-chevron-right:before {
  content: "\f054";
}

.icon-caret-down:before {
  content: "\f0d7";
}

.icon-caret-up:before {
  content: "\f0d8";
}

.icon-angle-left:before {
  content: "\f104";
}

.icon-angle-right:before {
  content: "\f105";
}

.icon-pencil:before {
  content: "\e906";
}

.icon-camera:before {
  content: "\e90f";
}

.icon-key:before {
  content: "\e98e";
}

.icon-menu-open:before {
  content: "\e9bf";
}

.icon-menu-close:before {
  content: "\e9c0";
}

.icon-close:before {
  content: "\ea0f";
}
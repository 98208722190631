#{$all-button-inputs},
button,
.button {
  @include appearance(none);
  -webkit-font-smoothing: antialiased;
  border: 1px solid $light-brown;
  color: $light-brown;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding: 0.75em 1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  &:hover,
  &:focus {
    background-color: darken($action-color, 15%);
    color: white;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  i {
    margin-right: 10px;
  }
}

.button--highlighted {
  color: white;
  background-image: url("img/button-highlighted-bg.jpg");
}

.button--wide {
  display: block;
  margin-bottom: 20px;

  .button-holder & {
    margin-bottom: 0;
    position: relative;
    z-index: 2;
  }
}

.button-holder {
  position: relative;
  background-color: $dark-brown;
  padding-top: 10px;

  &:before {
    content: '';
    display: block;
    background-image: url('img/footer-upper-brush-large.png');
    @include position(absolute, -16px 0 null 0);
    height: 41px;
  }
}

.more {
  margin: 10px 0 20px;
}

.directions-nav {
  position: relative;
  z-index: 2;
  background-image: url("img/directions-nav-bg.jpg");
  @include clearfix;

  li {
    width: 50%;
    a {
      display: block;
      font-size: 14px;
      font-weight: 800;
      color: white;
      padding: 14px 15px;
      text-transform: uppercase;
    }
  }
  .prev,
  .next {
    i {
      position: relative;
      top: 2px;
    }
  }
  .prev {
    float: left;
    border-right: 1px dashed $dark-brown;
    i {
      margin-right: 10px;
    }
  }
  .next {
    float: right;
    text-align: right;
    i {
      margin-left: 10px;
    }
  }
}
.leaderboard {
	padding-top: 20px;
}

.leaderboard__title {
	text-align: center;
	span {
		font-weight: 700;
		text-transform: uppercase;
	}

	@include media($large-screen-up) {
		text-align: left;
	}
}

.leaderboard__place {
	border-top: 1px solid $medium-brown;
	border-bottom: 1px solid $medium-brown;
	position: relative;
	padding-left: 60px;
}

.place {
	width: 60px;
	height: 100%;
	display: inline-block;
	font-size: 18px;
	font-weight: 700;
	color: $dark-brown;
	text-align: center;
	padding-top: 20px;
	@include position(absolute, 0 null null 0);

	.leaderboard--dotd & {
		background-image: url('img/leaderboard-dotd-bg.jpg');
	}

	.leaderboard--htfu & {
		background-image: url('img/leaderboard-htfu-bg.jpg');
	}
}

.leaderboard__ranks {
	display: inline-block;

	li {
		display: table;
		width: 100%;
		table-layout: fixed;
		padding: 10px 15px;
		border-bottom: 1px dashed $medium-brown;

		&:last-child {
			border-bottom: none;
		}

		div {
			display: table-cell;
		}
	}
}

.leaderboard__leadername {
	width: 145px;
	font-weight: 600;
	color: white;

	@include media($medium-screen-up) {
		width: 50%;
	}
}

.leaderboard__leaderavatar {
	width: 50px;
}
.card {
	text-align: center;
	padding: 30px 15px;
	border-bottom: 1px dashed $light-gray;
	position: relative;

	&:last-child {
		border-bottom: none;
	}

	@include media($large-screen-up) {
		width: 50%;
		height: 375px;
		float: left;
		text-align: left;
		padding: 50px 50px 50px 160px;
		border-right: 1px dashed $light-gray;
		@include omega(2n);

		&:nth-child(2n) {
			border-right: none;
		}
	}

	@include media(1280px) {
		padding-left: 256px;
	}

	.ie8 & {
		width: 32.3%;
		height: 280px;
		padding-bottom: 5px;
	}
}

.card__badge {
	margin-bottom: 20px;

	@include media($large-screen-up) {
		width: 100px;
		text-align: center;
		@include position(absolute, 30px null null 30px);
		opacity: 0;
		@include transform(translateY(150px));
		@include transition(all 0.8s ease);

		.come-in &,
		.already-visible & {
			opacity: 1;
			@include transform(translateY(0));
		}

		.come-in:nth-child(odd) & {
			@include animation-duration(0.4s);
		}
	}

	@include media(1280px) {
		width: 192px;
	}
}

.card__title {
	font-size: 21px;
	color: $light-brown;

	@include media($medium-screen-up) {
		font-size: 28px;
	}

	@include media($large-screen-up) {
		font-size: 36px;
		opacity: 0;
		@include transform(translateY(150px));
		@include transition(all 0.8s ease 0.3s);

		.come-in &,
		.already-visible & {
			opacity: 1;
			@include transform(translateY(0));
		}

		.come-in:nth-child(odd) & {
			@include animation-duration(0.4s);
		}
	}
}

.card__description {
	@include media($large-screen-up) {
		opacity: 0;
		@include transform(translateY(150px));
		@include transition(all 0.8s ease 0.5s);

		.come-in &,
		.already-visible & {
			opacity: 1;
			@include transform(translateY(0));
		}

		.come-in:nth-child(odd) & {
			@include animation-duration(0.4s);
		}
	}
}

.come-in {
	@include transform(translateY(150px));
	@include animation(come-in 0.8s ease forwards 0.3s);
}

.come-in:nth-child(odd) {
	@include animation-duration(0.6s);
}

.already-visible {
  @include transform(translateY(0));
  @include animation(none);
}
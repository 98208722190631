.site-content {
  @include clearfix;

  .ie8 & {
    overflow: hidden;
  }
}

.content-area {
  @include media($large-screen-up) {
    float: left;
    width: 62%;
  }
}

.content {
  overflow: hidden;
}

.content--has-bg {
  background: url("img/background-spots.jpg");
}

.content--has-bg-highlighted {
  background-image: url('img/brown-spots-bg.jpg');
}

.content--dark-bg {
  color: $lightest-gray;
  background-color: $dark-brown;
}

.content-wrapper {
  max-width: 690px;
  margin: 0 auto;
}

.article__entry {
  // display: block
  text-align: center;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;
  color: $light-brown;
  margin: 20px 0;

  span {
    @include dashes(1200px, 2px, rgba($light-brown, .4));
  }

  &--light {
    color: white;

    span {
      @include dashes(1200px, 2px, $medium-gray);
    }
  }

  @include media($large-screen-up) {
    font-size: 18px;
    text-align: left;
    padding-left: 15px;
  }
}
body {
  @include font-feature-settings("kern", "liga", "pnum");
  -webkit-font-smoothing: antialiased;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: $base-font-size;
  font-weight: normal;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

p {
  margin: 0 0 24px;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.1s linear;
  &:active,
  &:focus,
  &:hover {
    color: $action-color;
  }
  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
  vertical-align: middle;
}

figure,
blockquote {
  margin: 0;
}

figcaption {
  font-size: 13px;
  color: $medium-gray;
  padding: 10px 0;

  i {
    margin-right: 10px;
  }
}

q,
blockquote {
  font-family: $heading-font-family;
  font-style: italic;
  line-height: 1.3;
  margin-bottom: 20px;
}

q {
  &:before {
    content: "“";
  }
  &:after {
    content: "”";
  }
}

blockquote {
  font-size: 21px;
  color: $light-brown;

  @include media($large-screen-up) {
    font-size: 36px;
  }
}
.header-popup {
	background-color: $dark-brown;
	position: relative;
	padding: 30px 15px;
	border-bottom: 2px solid $light-brown;
	overflow: hidden;
}

.header-popup__inner {
	max-width: 630px;
	margin: 0 auto;
	position: relative;

	.close {
		position: absolute;
		top: 8px;
		right: -50px;
		color: $light-gray;

		.ie7 & {
			top: -8px;
		}
	}
}

.user-login {
	.login-form-holder {
		input {
			display: inline-block;
			zoom: 1;
			*display: inline;
		}

		.login-username,
		.login-password {
			position: relative;
			margin-bottom: 15px;

			label {
				font-family: 'proxima-nova';
				color: $light-gray;
				@include position(absolute, 50% null null 20px);
				margin-top: -18px;
				@include transition(all 0.2s);
			}

			&.has-focus {
				label {
					top: 0;
					font-size: 13px;
					color: white;
				}
			}

			input {
				font-family: 'proxima-nova';
				font-size: 16px;
				color: white;
				padding: 6px 20px 8px;
				background-color: $medium-brown;
				border-width: 1px;
				border-color: $light-brown;
				border-radius: 35px;

				&:focus {
					box-shadow: none;
					border-color: $light-gray;
				}

				.ie7 & {
					width: 180px;
				}

				@include media($medium-screen-up) {
					width: 250px;
				}
			}

			@include media($medium-screen-up) {
				display: inline-block;
				zoom: 1;
				*display: inline;
				margin-right: 20px;
			}
		}

		.login-remember {
			margin-bottom: 0;
			label {
				display: inline-block;
				margin-bottom: 0;
				font-family: 'proxima-nova';
				font-size: 14px;
				color: $light-gray;
			}
		}

		.login-submit {
			@include position(absolute, null 0 -30px null);

			input {
				color: white;
				background-color: $light-brown;
				border-color: $light-brown;

				&:hover {
					color: $light-gray;
					background-color: transparent;
				}
			}

			@include media($medium-screen-up) {
				top: 0;
				right: 0;
				bottom: auto;
			}
		}

		.ie7 & {
			height: 41px;
		}
	}

	.ie7 & {
		input.button {
			vertical-align: top;
		}
	}
}
.author {
	color: white;
	padding: 30px 15px;
	background-color: $light-brown;
	position: relative;

	&:before,
	&:after {
		content: '';
		display: block;
		@include size(100% 41px);
		@include position(absolute, null 0 null 0);
	}

	&:before {
		top: -16px;
		background-image: url('img/upper-brush-light.png');

		@include media($large-screen-up) {
			background-image: url('img/upper-brush-light-large.png');
		}
	}

	&:after {
		bottom: -16px;
		background-image: url('img/lower-brush-light.png');

		@include media($large-screen-up) {
			background-image: url('img/lower-brush-light-large.png');
		}
	}

	&__name {
		font-size: 18px;
		font-weight: 700;
		color: $dark-brown;
		text-transform: uppercase;
		margin-bottom: 5px;
	}

	&__avatar {
		width: 60px;
		float: right;
		margin-top: -30px;
	}
}

.author__inner {
	max-width: 660px;
  margin: 0 auto;
  position: relative;

  @include media($large-screen-up) {
		padding-left: 160px;

		.author__avatar {
			width: 105px;
			@include position(absolute, null null null 0);
		}
	}
}
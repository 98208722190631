// Adds dashes on heading sides
@mixin dashes($dashWidth, $dashHeight, $dashColor) {
	display: inline-block;
	position: relative;

	&:before,
	&:after {
		content: '';
		display: inline-block;
		@include size($dashWidth $dashHeight);
		background-color: $dashColor;
		position: absolute;
		top: 50%;
		margin-top: -2px;
	}

	&:before {
		right: 100%;
		margin-right: 10px;
	}

	&:after {
		left: 100%;
		margin-left: 10px;
	}
}

// Omega reset
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { 
  	margin-right: flex-gutter(); 
  }
  &:nth-child(#{$nth}+1) {
  	clear: none;
  }
}
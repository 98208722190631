.comments {
	max-width: 690px;
	margin: 0 auto;
	padding: 20px 15px;

	h2 {
		font-size: 21px;

		span {
			color: $light-gray;
		}
	}

	&--has-title {
		padding-top: 40px;
	}

	.comment {
		color: $medium-brown;
		padding-bottom: 20px;
		border-bottom: 1px dashed $light-gray;

		&:after {
			content: none;
		}

		&:last-child {
			border-bottom: none;
		}

		&-meta__author {
			display: block;
			line-height: 1;
		}

		.reply {
			@include position(absolute, null 0 20px null);
		}
	}

	@include media($medium-screen-up) {
		padding: 40px 15px;
	}

	@include media($large-screen-up) {
		padding: 60px 0;

		.comment-meta {
			li {
				display: inline-block;

				&:not(:last-child) {
					&:after {
						content: '•';
						display: inline-block;
						margin: 0 10px;
					}
				}
			}
		}
	}
}

.comment {
  position: relative;
  margin-bottom: 20px;

  p {
		font-family: $heading-font-family;
		font-size: 18px;
		font-style: italic;
		line-height: 1.3;
		margin-bottom: 20px;

		&:before {
	    content: "“";
	  }
	  &:after {
	    content: "”";
	  }
	}
  
  &:after {
    content: "";
    display: block;
    width: 50px;
    height: 1px;
    background-color: $light-brown;
    margin-top: 20px;
  }
}

.comment-meta {
  font-family: $base-font-family;
  li {
    display: inline-block;
  }
  &__author {
    font-weight: 700;
  }
  &__date {
    font-size: 14px;
  }
}

.comment-form-container {
	position: relative;
	background-color: $dark-brown;

	&:before {
		content: '';
		display: block;
		@include size(100% 41px);
		background-image: url('img/footer-upper-brush.png');
		@include position(absolute, -16px 0 null 0);

		@include media($large-screen-up) {
			background-image: url('img/footer-upper-brush-large.png');
		}
	}
}

// comment form
.comment-form {
	padding: 30px 15px;
	color: white;
	max-width: 690px;
  margin: 0 auto;

	&__title {
		font-size: 21px;
	}

	form {
		div {
			margin-bottom: 20px;
		}
	}

	input[type="submit"] {
		color: white;
		display: block;
		width: 100%;
	}
}
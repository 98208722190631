.sidebar {
  h2 {
    font-family: "proxima-nova";
    font-size: 14px;
    font-weight: 700;
    color: $light-gray;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  @include media($large-screen-up) {
    float: right;
    width: 38%;
  }
}

.sidebar--light {
  background-color: white;
}

.sidebar__inner {
  padding: 40px 15px;

  @include media($large-screen-up) {
    padding-left: 70px;
    padding-right: 70px;
  }
}
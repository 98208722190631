.main-footer {
  font-size: 13px;
  color: $light-gray;
  text-align: center;
  background-color: $dark-brown;
  padding: 20px 15px;
  position: relative;

  p {
    margin-bottom: 0;
  }

  &:before {
    content: "";
    display: block;
    @include size(100% 41px);
    background-image: url("img/footer-upper-brush.png");
    @include position(absolute, -16px 0 null 0);

    @include media($large-screen-up) {
      background-image: url('img/footer-upper-brush-large.png');
    }
  }

  @include media($large-screen-up) {
    margin-top: -1px;
    padding: 53px 30px;
  }
}

.footer-logo {
  margin: 20px 0;

  @include media($large-screen-up) {
    margin-bottom: 50px;
  }
}

.footer-copy {
  @include media($large-screen-up) {
    p {
      display: inline-block;

      &:not(:last-child) {
        &:after {
          content: '•';
          display: inline-block;
          margin: 0 10px;
        }
      }
    }
  }
}
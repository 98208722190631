.sprite {
  background-image: url("../images/sprite.png");
  background-repeat: no-repeat;
  overflow: hidden;
}

.center {
  text-align: center;
}

.left {
  float: left;
}

.right {
  float: right;
}

.is-hidden {
  display: none;
}

.is-invisible {
  visibility: hidden;
}

.desktop-hidden {
	@include media($large-screen-up) {
		display: none;
	}
}

.desktop-revealed {
	display: none;

	@include media($large-screen-up) {
		display: block;
	}
}
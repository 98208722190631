@charset "UTF-8";
/*!
Theme Name: Africa200
Author: Merchant Technology Marketing
Author URI: http://merchanttechnology.co.uk
Version: 1.0.0
*/
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

audio, canvas, video, progress {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

html, button, input, select, textarea {
  font-family: sans-serif; }

a:hover, a:active {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: yellow;
  color: black; }

pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: "courier new", monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0;
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button,
input[type="button"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

input {
  line-height: normal; }
  input::-moz-focus-inner {
    border: 0;
    padding: 0; }
  input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    height: auto; }
  input[type="search"] {
    box-sizing: content-box; }
    input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none; } }
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -moz-transform: none; } }
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }
@-webkit-keyframes come-in {
  to {
    transform: translateY(0); } }
@-moz-keyframes come-in {
  to {
    transform: translateY(0); } }
@keyframes come-in {
  to {
    transform: translateY(0); } }
button, input[type="button"], input[type="reset"], input[type="submit"],
button,
.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #b4532f;
  color: #b4532f;
  cursor: pointer;
  display: inline-block;
  font-family: "proxima-nova", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding: 0.75em 1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus,
  button:hover,
  button:focus,
  .button:hover,
  .button:focus {
    background-color: #77371f;
    color: white; }
  button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled,
  button:disabled,
  .button:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  button i, input[type="button"] i, input[type="reset"] i, input[type="submit"] i,
  button i,
  .button i {
    margin-right: 10px; }

.button--highlighted {
  color: white;
  background-image: url("img/button-highlighted-bg.jpg"); }

.button--wide {
  display: block;
  margin-bottom: 20px; }
  .button-holder .button--wide {
    margin-bottom: 0;
    position: relative;
    z-index: 2; }

.button-holder {
  position: relative;
  background-color: #190a06;
  padding-top: 10px; }
  .button-holder:before {
    content: '';
    display: block;
    background-image: url("img/footer-upper-brush-large.png");
    position: absolute;
    top: -16px;
    right: 0;
    left: 0;
    height: 41px; }

.more {
  margin: 10px 0 20px; }

.directions-nav {
  position: relative;
  z-index: 2;
  background-image: url("img/directions-nav-bg.jpg"); }
  .directions-nav::after {
    clear: both;
    content: "";
    display: table; }
  .directions-nav li {
    width: 50%; }
    .directions-nav li a {
      display: block;
      font-size: 14px;
      font-weight: 800;
      color: white;
      padding: 14px 15px;
      text-transform: uppercase; }
  .directions-nav .prev i,
  .directions-nav .next i {
    position: relative;
    top: 2px; }
  .directions-nav .prev {
    float: left;
    border-right: 1px dashed #190a06; }
    .directions-nav .prev i {
      margin-right: 10px; }
  .directions-nav .next {
    float: right;
    text-align: right; }
    .directions-nav .next i {
      margin-left: 10px; }

fieldset {
  background-color: #d2cfcf;
  border: 1px solid #bab5b4;
  margin: 0 0 0.75em;
  padding: 1.5em; }

input,
label,
select {
  display: block;
  font-family: "proxima-nova", sans-serif;
  font-size: 1em; }

label {
  font-weight: 600;
  margin-bottom: 0.375em; }
  label.required::after {
    content: "*"; }
  label abbr {
    display: none; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea,
select[multiple=multiple],
textarea {
  background-color: #473b38;
  border: 1px solid #190a06;
  box-sizing: border-box;
  font-family: "proxima-nova", sans-serif;
  font-size: 1em;
  margin-bottom: 0.75em;
  padding: 0.5em;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, textarea:hover,
  select[multiple=multiple]:hover,
  textarea:hover {
    border-color: #a29b99; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, textarea:focus,
  select[multiple=multiple]:focus,
  textarea:focus {
    border-color: #b4532f;
    box-shadow: none;
    outline: none; }
  input[type="color"].error, input[type="date"].error, input[type="datetime"].error, input[type="datetime-local"].error, input[type="email"].error, input[type="month"].error, input[type="number"].error, input[type="password"].error, input[type="search"].error, input[type="tel"].error, input[type="text"].error, input[type="time"].error, input[type="url"].error, input[type="week"].error, textarea.error,
  select[multiple=multiple].error,
  textarea.error {
    border-color: red; }

textarea {
  resize: none; }

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

input[type="file"] {
  padding-bottom: 0.75em;
  width: 100%; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  max-width: 100%;
  width: auto;
  background-color: transparent;
  border: none; }

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?wj3o2p");
  src: url("fonts/icomoon.eot?#iefixwj3o2p") format("embedded-opentype"), url("fonts/icomoon.woff?wj3o2p") format("woff"), url("fonts/icomoon.ttf?wj3o2p") format("truetype"), url("fonts/icomoon.svg?wj3o2p#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }
[class^="icon-"], [class*=" icon-"] {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-message:before {
  content: "\e600"; }

.icon-chevron-left:before {
  content: "\f053"; }

.icon-chevron-right:before {
  content: "\f054"; }

.icon-caret-down:before {
  content: "\f0d7"; }

.icon-caret-up:before {
  content: "\f0d8"; }

.icon-angle-left:before {
  content: "\f104"; }

.icon-angle-right:before {
  content: "\f105"; }

.icon-pencil:before {
  content: "\e906"; }

.icon-camera:before {
  content: "\e90f"; }

.icon-key:before {
  content: "\e98e"; }

.icon-menu-open:before {
  content: "\e9bf"; }

.icon-menu-close:before {
  content: "\e9c0"; }

.icon-close:before {
  content: "\ea0f"; }

.link {
  font-size: 13px;
  font-weight: 700;
  color: #b4532f;
  text-transform: uppercase; }
  .link i {
    margin-right: 10px; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .hentry.post .entry-content ul {
    list-style-type: disc;
    margin-bottom: 24px;
    padding-left: 1.5em; }
  .hentry.post .entry-content ol {
    list-style-type: decimal;
    margin-bottom: 24px;
    padding-left: 1.5em; }

dl {
  margin-bottom: 0.75em; }
  dl dt {
    font-weight: bold;
    margin-top: 0.75em; }
  dl dd {
    margin: 0; }

.inline li {
  display: inline-block;
  zoom: 1;
  *display: inline; }
  .inline li:after {
    content: "•";
    display: inline-block;
    color: inherit;
    position: relative;
    top: -1px;
    margin: 0 5px 0 9px; }
  .inline li:last-child:after {
    content: none; }

table {
  -webkit-font-feature-settings: "kern", "liga", "tnum";
  -moz-font-feature-settings: "kern", "liga", "tnum";
  -ms-font-feature-settings: "kern", "liga", "tnum";
  font-feature-settings: "kern", "liga", "tnum";
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #958e8c;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #bab5b4;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

body {
  -webkit-font-feature-settings: "kern", "liga", "pnum";
  -moz-font-feature-settings: "kern", "liga", "pnum";
  -ms-font-feature-settings: "kern", "liga", "pnum";
  font-feature-settings: "kern", "liga", "pnum";
  -webkit-font-smoothing: antialiased;
  color: #190a06;
  font-family: "proxima-nova", sans-serif;
  font-size: 1em;
  line-height: 1.5; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "schoolbook-web";
  font-size: 1em;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 0 0.75em; }

p {
  margin: 0 0 24px; }

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.1s linear; }
  a:active, a:focus, a:hover {
    color: #b4532f; }
  a:active, a:focus {
    outline: none; }

hr {
  border-bottom: 1px solid #bab5b4;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: 1.5em 0; }

img,
picture {
  margin: 0;
  max-width: 100%;
  vertical-align: middle; }

figure,
blockquote {
  margin: 0; }

figcaption {
  font-size: 13px;
  color: #8c8482;
  padding: 10px 0; }
  figcaption i {
    margin-right: 10px; }

q,
blockquote {
  font-family: "schoolbook-web";
  font-style: italic;
  line-height: 1.3;
  margin-bottom: 20px; }

q:before {
  content: "“"; }
q:after {
  content: "”"; }

blockquote {
  font-size: 21px;
  color: #b4532f; }
  @media screen and (min-width: 53.75em) {
    blockquote {
      font-size: 36px; } }

.sprite {
  background-image: url("../images/sprite.png");
  background-repeat: no-repeat;
  overflow: hidden; }

.center {
  text-align: center; }

.left {
  float: left; }

.right {
  float: right; }

.is-hidden {
  display: none; }

.is-invisible {
  visibility: hidden; }

@media screen and (min-width: 53.75em) {
  .desktop-hidden {
    display: none; } }

.desktop-revealed {
  display: none; }
  @media screen and (min-width: 53.75em) {
    .desktop-revealed {
      display: block; } }

.site-header {
  background-color: #190a06; }

.site-header__actions {
  position: relative;
  padding: 16px 15px; }
  .site-header__actions::after {
    clear: both;
    content: "";
    display: table; }

.hamburger,
.login {
  font-size: 26px;
  line-height: 0; }

.hamburger {
  float: left;
  color: #b4532f; }
  @media screen and (min-width: 53.75em) {
    .hamburger {
      display: none; } }

.login {
  float: right;
  color: #8c8482; }
  .login:visited, .login:focus {
    color: #8c8482; }
  .login.is-active {
    color: white; }
  .ie8 .login {
    position: absolute;
    top: 10px;
    right: 10px; }

.logo {
  display: block;
  width: 116px;
  position: absolute;
  left: 50%;
  z-index: 3;
  margin-left: -58px; }
  @media screen and (min-width: 53.75em) {
    .logo {
      width: 198px;
      margin-left: -99px; } }
  .ie8 .logo {
    margin-top: -90px; }

.site-content::after {
  clear: both;
  content: "";
  display: table; }
.ie8 .site-content {
  overflow: hidden; }

@media screen and (min-width: 53.75em) {
  .content-area {
    float: left;
    width: 62%; } }

.content {
  overflow: hidden; }

.content--has-bg {
  background: url("img/background-spots.jpg"); }

.content--has-bg-highlighted {
  background-image: url("img/brown-spots-bg.jpg"); }

.content--dark-bg {
  color: #D1CECD;
  background-color: #190a06; }

.content-wrapper {
  max-width: 690px;
  margin: 0 auto; }

.article__entry {
  text-align: center;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;
  color: #b4532f;
  margin: 20px 0; }
  .article__entry span {
    display: inline-block;
    position: relative; }
    .article__entry span:before, .article__entry span:after {
      content: '';
      display: inline-block;
      height: 2px;
      width: 1200px;
      background-color: rgba(180, 83, 47, 0.4);
      position: absolute;
      top: 50%;
      margin-top: -2px; }
    .article__entry span:before {
      right: 100%;
      margin-right: 10px; }
    .article__entry span:after {
      left: 100%;
      margin-left: 10px; }
  .article__entry--light {
    color: white; }
    .article__entry--light span {
      display: inline-block;
      position: relative; }
      .article__entry--light span:before, .article__entry--light span:after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 1200px;
        background-color: #8c8482;
        position: absolute;
        top: 50%;
        margin-top: -2px; }
      .article__entry--light span:before {
        right: 100%;
        margin-right: 10px; }
      .article__entry--light span:after {
        left: 100%;
        margin-left: 10px; }
  @media screen and (min-width: 53.75em) {
    .article__entry {
      font-size: 18px;
      text-align: left;
      padding-left: 15px; } }

.main-footer {
  font-size: 13px;
  color: #bab5b4;
  text-align: center;
  background-color: #190a06;
  padding: 20px 15px;
  position: relative; }
  .main-footer p {
    margin-bottom: 0; }
  .main-footer:before {
    content: "";
    display: block;
    height: 41px;
    width: 100%;
    background-image: url("img/footer-upper-brush.png");
    position: absolute;
    top: -16px;
    right: 0;
    left: 0; }
    @media screen and (min-width: 53.75em) {
      .main-footer:before {
        background-image: url("img/footer-upper-brush-large.png"); } }
  @media screen and (min-width: 53.75em) {
    .main-footer {
      margin-top: -1px;
      padding: 53px 30px; } }

.footer-logo {
  margin: 20px 0; }
  @media screen and (min-width: 53.75em) {
    .footer-logo {
      margin-bottom: 50px; } }

@media screen and (min-width: 53.75em) {
  .footer-copy p {
    display: inline-block; }
    .footer-copy p:not(:last-child):after {
      content: '•';
      display: inline-block;
      margin: 0 10px; } }

.author {
  color: white;
  padding: 30px 15px;
  background-color: #b4532f;
  position: relative; }
  .author:before, .author:after {
    content: '';
    display: block;
    height: 41px;
    width: 100%;
    position: absolute;
    right: 0;
    left: 0; }
  .author:before {
    top: -16px;
    background-image: url("img/upper-brush-light.png"); }
    @media screen and (min-width: 53.75em) {
      .author:before {
        background-image: url("img/upper-brush-light-large.png"); } }
  .author:after {
    bottom: -16px;
    background-image: url("img/lower-brush-light.png"); }
    @media screen and (min-width: 53.75em) {
      .author:after {
        background-image: url("img/lower-brush-light-large.png"); } }
  .author__name {
    font-size: 18px;
    font-weight: 700;
    color: #190a06;
    text-transform: uppercase;
    margin-bottom: 5px; }
  .author__avatar {
    width: 60px;
    float: right;
    margin-top: -30px; }

.author__inner {
  max-width: 660px;
  margin: 0 auto;
  position: relative; }
  @media screen and (min-width: 53.75em) {
    .author__inner {
      padding-left: 160px; }
      .author__inner .author__avatar {
        width: 105px;
        position: absolute;
        left: 0; } }

.actions-bar {
  color: #bab5b4;
  padding: 20px 15px 35px;
  background-image: url("img/actions-bar-bg.png");
  position: relative;
  z-index: 2;
  margin-bottom: -16px; }
  .actions-bar::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (min-width: 53.75em) {
    .actions-bar {
      background-image: none;
      background-color: rgba(25, 10, 6, 0.7);
      height: 56px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: -101px;
      margin-bottom: 4px; }
      .actions-bar .button--new-message {
        display: inline-block;
        float: right;
        position: relative;
        top: -8px;
        margin-bottom: 0; }
      .actions-bar:before {
        content: '';
        display: block;
        height: 22px;
        background-image: url("img/actionsbar-lower-brush-light.png");
        background-size: contain;
        background-repeat: repeat-x;
        position: absolute;
        right: 0;
        bottom: -22px;
        left: 0; }
      .no-rgba .actions-bar {
        background-image: url("img/norgba-actionsbar-bg-light.png"); }
      .ie8 .actions-bar {
        height: 18px; } }

.filters {
  font-size: 0.1px;
  text-align: justify; }
  .filters:after {
    content: "";
    width: 100%;
    display: inline-block; }
  .filters .filter {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    zoom: 1;
    *display: inline;
    padding-right: 20px;
    background: url("img/dropdown-arrow.png") 110px 9px no-repeat; }
  @media screen and (min-width: 53.75em) {
    .filters {
      display: inline-block; }
      .filters .filter {
        margin-right: 20px; } }
  .ie8 .filters {
    overflow: hidden;
    width: 400px; }
    .ie8 .filters .filter {
      float: left; }

.entry-header {
  position: relative;
  padding: 20px 15px 0; }

.entry-title {
  width: 75%;
  font-size: 21px;
  margin-bottom: 10px; }
  @media screen and (min-width: 40em) {
    .entry-title {
      font-size: 28px; } }
  @media screen and (min-width: 53.75em) {
    .entry-title {
      font-size: 36px; } }

.entry-author-avatar {
  width: 50px;
  position: absolute;
  top: 20px;
  right: 15px; }
  @media screen and (min-width: 53.75em) {
    .entry-author-avatar {
      width: 60px;
      right: 15px; } }

.entry-meta li {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #8c8482; }
  .entry-meta li a {
    color: #8c8482; }
  .entry-meta li.entry-meta__author {
    display: none; }
    @media screen and (min-width: 40em) {
      .entry-meta li.entry-meta__author {
        display: inline-block; } }
.ie8 .entry-meta {
  overflow: hidden; }
  .ie8 .entry-meta li {
    display: inline; }

.entry-content {
  padding-bottom: 20px; }
  .entry-content figure,
  .entry-content .media-holder {
    margin: 20px 15px; }
  @media screen and (min-width: 40em) {
    .entry-content .media-holder {
      height: 370px; } }
  .entry-content figure .image-holder {
    position: relative;
    height: 124px;
    overflow: hidden;
    transform-style: preserve-3d; }
    .entry-content figure .image-holder img {
      position: relative;
      opacity: 1 !important; }
    @media screen and (min-width: 40em) {
      .entry-content figure .image-holder {
        height: 290px; } }

.entry-content--about > blockquote {
  padding: 0 35px;
  text-align: center; }

.header-image {
  margin-bottom: 24px; }

.header-image__holder {
  position: relative;
  height: 124px;
  overflow: hidden;
  transform-style: preserve-3d; }
  .header-image__holder img {
    position: relative;
    opacity: 1 !important; }
  @media screen and (min-width: 40em) {
    .header-image__holder {
      height: 360px; } }
  @media screen and (min-width: 53.75em) {
    .header-image__holder {
      height: 560px; } }

.entry-content__inner {
  padding: 0 15px; }
  .entry-content__inner figure {
    margin: 20px 0; }

.hentry {
  border-bottom: 1px dashed #bab5b4; }
  .hentry:last-child {
    border-bottom: none; }
  .hentry.post .entry-header {
    color: white;
    background-color: #190a06;
    padding-top: 120px;
    padding-bottom: 20px; }
    @media screen and (min-width: 53.75em) {
      .hentry.post .entry-header {
        padding-top: 150px;
        padding-bottom: 40px; } }
  .hentry.post .entry-content figure,
  .hentry.post .entry-content .media-holder {
    margin: 0 0 20px; }
  .hentry.post .entry-content h1, .hentry.post .entry-content h2, .hentry.post .entry-content h3, .hentry.post .entry-content h4, .hentry.post .entry-content h5, .hentry.post .entry-content h6 {
    margin-bottom: 24px; }
  .hentry.post .entry-content h2 {
    font-size: 24px; }
  .hentry.post .entry-content h3 {
    font-size: 20px; }
  .hentry.post .entry-content h4 {
    font-size: 16px; }
  .hentry.post .entry-content h5 {
    font-size: 14px; }
  .hentry.post .entry-content h6 {
    font-size: 12px; }
  .hentry.post .entry-content__inner {
    max-width: 690px;
    margin: 0 auto; }
  .hentry--no-border {
    border-bottom: none; }
    .hentry--no-border .entry-header {
      padding-top: 0; }
    .hentry--no-border .entry-author-avatar {
      width: 70px;
      top: -10px; }
    .hentry--no-border .entry-meta {
      margin-bottom: 10px; }
    .hentry--no-border .entry-content {
      padding-bottom: 0; }

@media screen and (min-width: 53.75em) {
  .article-group {
    padding-left: 55px;
    padding-right: 55px; } }

.content--dotd .entry-title {
  color: #8aa147; }

.content--htfu .entry-title {
  color: #b890a4; }

.hentry--highlighted {
  color: #d1cecd;
  text-align: center;
  margin: 20px 15px;
  background: url(img/entry-highlighted-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-bottom: none; }
  .hentry--highlighted .entry-title {
    width: 100%;
    margin-top: 20px; }
  @media screen and (min-width: 53.75em) {
    .hentry--highlighted {
      background: url("img/entry-highlighted-bg-large.jpg");
      background-size: cover; } }
  .ie8 .hentry--highlighted .entry-meta {
    padding-left: 150px; }

.entry-title--dotd {
  color: #8aa147; }

.hentry--highlighted__entry-header {
  margin-top: 60px; }

.hentry--highlighted__logo {
  width: 117px;
  margin: 0 auto; }
  .hentry--highlighted__logo img {
    margin-top: -40px; }

.hentry--highlighted__avatar {
  width: 142px;
  margin: 10px auto; }

.intro-title {
  font-weight: 700;
  color: #b4532f;
  text-transform: uppercase;
  padding: 30px 15px 0;
  margin-bottom: 0; }

.content--dotd .entry-author-avatar img,
.content--htfu .entry-author-avatar img {
  position: absolute;
  display: none; }
  .content--dotd .entry-author-avatar img.show-avatar,
  .content--htfu .entry-author-avatar img.show-avatar {
    display: block; }
@media screen and (min-width: 53.75em) {
  .content--dotd,
  .content--htfu {
    padding-left: 55px;
    padding-right: 55px; }
    .content--dotd .hentry,
    .content--htfu .hentry {
      position: relative;
      border-bottom: 1px dashed #bab5b4;
      padding: 30px 180px 30px 80px; }
      .content--dotd .hentry:last-child,
      .content--htfu .hentry:last-child {
        border-bottom: none; }
    .content--dotd .article__entry,
    .content--htfu .article__entry {
      position: absolute;
      left: -15px;
      margin: 10px 0; }
      .content--dotd .article__entry span:before, .content--dotd .article__entry span:after,
      .content--htfu .article__entry span:before,
      .content--htfu .article__entry span:after {
        content: none; }
    .content--dotd .entry-title,
    .content--htfu .entry-title {
      width: 100%; }
    .content--dotd .entry-author-avatar,
    .content--htfu .entry-author-avatar {
      width: 100px;
      right: -160px; } }

.card {
  text-align: center;
  padding: 30px 15px;
  border-bottom: 1px dashed #bab5b4;
  position: relative; }
  .card:last-child {
    border-bottom: none; }
  @media screen and (min-width: 53.75em) {
    .card {
      width: 50%;
      height: 375px;
      float: left;
      text-align: left;
      padding: 50px 50px 50px 160px;
      border-right: 1px dashed #bab5b4; }
      .card:nth-child(2n) {
        margin-right: 0; }
      .card:nth-child(2n+1) {
        clear: left; }
      .card:nth-child(2n) {
        border-right: none; } }
  @media screen and (min-width: 1280px) {
    .card {
      padding-left: 256px; } }
  .ie8 .card {
    width: 32.3%;
    height: 280px;
    padding-bottom: 5px; }

.card__badge {
  margin-bottom: 20px; }
  @media screen and (min-width: 53.75em) {
    .card__badge {
      width: 100px;
      text-align: center;
      position: absolute;
      top: 30px;
      left: 30px;
      opacity: 0;
      -webkit-transform: translateY(150px);
      -moz-transform: translateY(150px);
      -ms-transform: translateY(150px);
      -o-transform: translateY(150px);
      transform: translateY(150px);
      -webkit-transition: all 0.8s ease;
      -moz-transition: all 0.8s ease;
      transition: all 0.8s ease; }
      .come-in .card__badge, .already-visible .card__badge {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0); }
      .come-in:nth-child(odd) .card__badge {
        -webkit-animation-duration: 0.4s;
        -moz-animation-duration: 0.4s;
        animation-duration: 0.4s; } }
  @media screen and (min-width: 1280px) {
    .card__badge {
      width: 192px; } }

.card__title {
  font-size: 21px;
  color: #b4532f; }
  @media screen and (min-width: 40em) {
    .card__title {
      font-size: 28px; } }
  @media screen and (min-width: 53.75em) {
    .card__title {
      font-size: 36px;
      opacity: 0;
      -webkit-transform: translateY(150px);
      -moz-transform: translateY(150px);
      -ms-transform: translateY(150px);
      -o-transform: translateY(150px);
      transform: translateY(150px);
      -webkit-transition: all 0.8s ease 0.3s;
      -moz-transition: all 0.8s ease 0.3s;
      transition: all 0.8s ease 0.3s; }
      .come-in .card__title, .already-visible .card__title {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0); }
      .come-in:nth-child(odd) .card__title {
        -webkit-animation-duration: 0.4s;
        -moz-animation-duration: 0.4s;
        animation-duration: 0.4s; } }

@media screen and (min-width: 53.75em) {
  .card__description {
    opacity: 0;
    -webkit-transform: translateY(150px);
    -moz-transform: translateY(150px);
    -ms-transform: translateY(150px);
    -o-transform: translateY(150px);
    transform: translateY(150px);
    -webkit-transition: all 0.8s ease 0.5s;
    -moz-transition: all 0.8s ease 0.5s;
    transition: all 0.8s ease 0.5s; }
    .come-in .card__description, .already-visible .card__description {
      opacity: 1;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
    .come-in:nth-child(odd) .card__description {
      -webkit-animation-duration: 0.4s;
      -moz-animation-duration: 0.4s;
      animation-duration: 0.4s; } }

.come-in {
  -webkit-transform: translateY(150px);
  -moz-transform: translateY(150px);
  -ms-transform: translateY(150px);
  -o-transform: translateY(150px);
  transform: translateY(150px);
  -webkit-animation: come-in 0.8s ease forwards 0.3s;
  -moz-animation: come-in 0.8s ease forwards 0.3s;
  animation: come-in 0.8s ease forwards 0.3s; }

.come-in:nth-child(odd) {
  -webkit-animation-duration: 0.6s;
  -moz-animation-duration: 0.6s;
  animation-duration: 0.6s; }

.already-visible {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-animation: none;
  -moz-animation: none;
  animation: none; }

.comments {
  max-width: 690px;
  margin: 0 auto;
  padding: 20px 15px; }
  .comments h2 {
    font-size: 21px; }
    .comments h2 span {
      color: #bab5b4; }
  .comments--has-title {
    padding-top: 40px; }
  .comments .comment {
    color: #473b38;
    padding-bottom: 20px;
    border-bottom: 1px dashed #bab5b4; }
    .comments .comment:after {
      content: none; }
    .comments .comment:last-child {
      border-bottom: none; }
    .comments .comment-meta__author {
      display: block;
      line-height: 1; }
    .comments .comment .reply {
      position: absolute;
      right: 0;
      bottom: 20px; }
  @media screen and (min-width: 40em) {
    .comments {
      padding: 40px 15px; } }
  @media screen and (min-width: 53.75em) {
    .comments {
      padding: 60px 0; }
      .comments .comment-meta li {
        display: inline-block; }
        .comments .comment-meta li:not(:last-child):after {
          content: '•';
          display: inline-block;
          margin: 0 10px; } }

.comment {
  position: relative;
  margin-bottom: 20px; }
  .comment p {
    font-family: "schoolbook-web";
    font-size: 18px;
    font-style: italic;
    line-height: 1.3;
    margin-bottom: 20px; }
    .comment p:before {
      content: "“"; }
    .comment p:after {
      content: "”"; }
  .comment:after {
    content: "";
    display: block;
    width: 50px;
    height: 1px;
    background-color: #b4532f;
    margin-top: 20px; }

.comment-meta {
  font-family: "proxima-nova", sans-serif; }
  .comment-meta li {
    display: inline-block; }
  .comment-meta__author {
    font-weight: 700; }
  .comment-meta__date {
    font-size: 14px; }

.comment-form-container {
  position: relative;
  background-color: #190a06; }
  .comment-form-container:before {
    content: '';
    display: block;
    height: 41px;
    width: 100%;
    background-image: url("img/footer-upper-brush.png");
    position: absolute;
    top: -16px;
    right: 0;
    left: 0; }
    @media screen and (min-width: 53.75em) {
      .comment-form-container:before {
        background-image: url("img/footer-upper-brush-large.png"); } }

.comment-form {
  padding: 30px 15px;
  color: white;
  max-width: 690px;
  margin: 0 auto; }
  .comment-form__title {
    font-size: 21px; }
  .comment-form form div {
    margin-bottom: 20px; }
  .comment-form input[type="submit"] {
    color: white;
    display: block;
    width: 100%; }

.featured {
  min-height: 380px;
  color: white;
  text-align: center;
  text-shadow: 0 0 16px #8c8482;
  padding-top: 145px;
  background-size: cover;
  background-position: 50%; }
  @media screen and (min-width: 53.75em) {
    .featured {
      min-height: 550px;
      padding-top: 160px; } }

.pretitle {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase; }
  .pretitle span {
    display: inline-block;
    position: relative; }
    .pretitle span:before, .pretitle span:after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 40px;
      background-color: white;
      position: absolute;
      top: 50%;
      margin-top: -2px; }
    .pretitle span:before {
      right: 100%;
      margin-right: 10px; }
    .pretitle span:after {
      left: 100%;
      margin-left: 10px; }

.title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px; }
  @media screen and (min-width: 40em) {
    .title {
      font-size: 52px; } }
  @media screen and (min-width: 53.75em) {
    .title {
      font-size: 76px; } }

.title--spots {
  position: relative; }
  .title--spots:before {
    content: '';
    display: block;
    background-image: url("img/title-spots-overlay.png");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }

.featured__metas {
  font-size: 14px;
  font-weight: 700; }

.header-popup {
  background-color: #190a06;
  position: relative;
  padding: 30px 15px;
  border-bottom: 2px solid #b4532f;
  overflow: hidden; }

.header-popup__inner {
  max-width: 630px;
  margin: 0 auto;
  position: relative; }
  .header-popup__inner .close {
    position: absolute;
    top: 8px;
    right: -50px;
    color: #bab5b4; }
    .ie7 .header-popup__inner .close {
      top: -8px; }

.user-login .login-form-holder input {
  display: inline-block;
  zoom: 1;
  *display: inline; }
.user-login .login-form-holder .login-username,
.user-login .login-form-holder .login-password {
  position: relative;
  margin-bottom: 15px; }
  .user-login .login-form-holder .login-username label,
  .user-login .login-form-holder .login-password label {
    font-family: 'proxima-nova';
    color: #bab5b4;
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -18px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s; }
  .user-login .login-form-holder .login-username.has-focus label,
  .user-login .login-form-holder .login-password.has-focus label {
    top: 0;
    font-size: 13px;
    color: white; }
  .user-login .login-form-holder .login-username input,
  .user-login .login-form-holder .login-password input {
    font-family: 'proxima-nova';
    font-size: 16px;
    color: white;
    padding: 6px 20px 8px;
    background-color: #473b38;
    border-width: 1px;
    border-color: #b4532f;
    border-radius: 35px; }
    .user-login .login-form-holder .login-username input:focus,
    .user-login .login-form-holder .login-password input:focus {
      box-shadow: none;
      border-color: #bab5b4; }
    .ie7 .user-login .login-form-holder .login-username input, .ie7
    .user-login .login-form-holder .login-password input {
      width: 180px; }
    @media screen and (min-width: 40em) {
      .user-login .login-form-holder .login-username input,
      .user-login .login-form-holder .login-password input {
        width: 250px; } }
  @media screen and (min-width: 40em) {
    .user-login .login-form-holder .login-username,
    .user-login .login-form-holder .login-password {
      display: inline-block;
      zoom: 1;
      *display: inline;
      margin-right: 20px; } }
.user-login .login-form-holder .login-remember {
  margin-bottom: 0; }
  .user-login .login-form-holder .login-remember label {
    display: inline-block;
    margin-bottom: 0;
    font-family: 'proxima-nova';
    font-size: 14px;
    color: #bab5b4; }
.user-login .login-form-holder .login-submit {
  position: absolute;
  right: 0;
  bottom: -30px; }
  .user-login .login-form-holder .login-submit input {
    color: white;
    background-color: #b4532f;
    border-color: #b4532f; }
    .user-login .login-form-holder .login-submit input:hover {
      color: #bab5b4;
      background-color: transparent; }
  @media screen and (min-width: 40em) {
    .user-login .login-form-holder .login-submit {
      top: 0;
      right: 0;
      bottom: auto; } }
.ie7 .user-login .login-form-holder {
  height: 41px; }
.ie7 .user-login input.button {
  vertical-align: top; }

.leaderboard {
  padding-top: 20px; }

.leaderboard__title {
  text-align: center; }
  .leaderboard__title span {
    font-weight: 700;
    text-transform: uppercase; }
  @media screen and (min-width: 53.75em) {
    .leaderboard__title {
      text-align: left; } }

.leaderboard__place {
  border-top: 1px solid #473b38;
  border-bottom: 1px solid #473b38;
  position: relative;
  padding-left: 60px; }

.place {
  width: 60px;
  height: 100%;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: #190a06;
  text-align: center;
  padding-top: 20px;
  position: absolute;
  top: 0;
  left: 0; }
  .leaderboard--dotd .place {
    background-image: url("img/leaderboard-dotd-bg.jpg"); }
  .leaderboard--htfu .place {
    background-image: url("img/leaderboard-htfu-bg.jpg"); }

.leaderboard__ranks {
  display: inline-block; }
  .leaderboard__ranks li {
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 10px 15px;
    border-bottom: 1px dashed #473b38; }
    .leaderboard__ranks li:last-child {
      border-bottom: none; }
    .leaderboard__ranks li div {
      display: table-cell; }

.leaderboard__leadername {
  width: 145px;
  font-weight: 600;
  color: white; }
  @media screen and (min-width: 40em) {
    .leaderboard__leadername {
      width: 50%; } }

.leaderboard__leaderavatar {
  width: 50px; }

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s; }

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 846px;
  min-width: 320px;
  height: auto;
  z-index: 20;
  visibility: hidden;
  transform: translateX(-50%) translateY(-50%); }

.modal__inner {
  background-color: white;
  padding: 70px;
  position: relative;
  margin: 0 auto;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s; }
  .modal__inner .comment-form__title {
    font-family: "schoolbook-web";
    font-size: 21px;
    font-weight: 700;
    color: #190a06;
    text-align: center;
    position: relative; }
    .modal__inner .comment-form__title span {
      display: inline-block;
      position: relative; }
      .modal__inner .comment-form__title span:before, .modal__inner .comment-form__title span:after {
        content: '';
        display: inline-block;
        height: 1px;
        width: 600px;
        background-color: #190a06;
        position: absolute;
        top: 50%;
        margin-top: -2px; }
      .modal__inner .comment-form__title span:before {
        right: 100%;
        margin-right: 10px; }
      .modal__inner .comment-form__title span:after {
        left: 100%;
        margin-left: 10px; }
    @media screen and (min-width: 53.75em) {
      .modal__inner .comment-form__title {
        font-size: 36px; } }
    .modal__inner .comment-form__title:before {
      content: '';
      display: block;
      background-image: url("img/title-spots-white.png");
      background-position: 50%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1; }
  .modal__inner .comment-form {
    color: #190a06;
    padding: 0;
    overflow: hidden; }
    .modal__inner .comment-form input[type='text'],
    .modal__inner .comment-form textarea {
      background-color: #F3F3F3;
      border-color: #E8E6E6; }
      .modal__inner .comment-form input[type='text'].error,
      .modal__inner .comment-form textarea.error {
        border-color: red; }
  .modal__inner:before, .modal__inner:after {
    content: '';
    display: block;
    height: 41px;
    width: 100%;
    position: absolute;
    right: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat; }
  .modal__inner:before {
    top: -16px;
    background-image: url("img/modal-upper-brush.png"); }
  .modal__inner:after {
    bottom: -16px;
    background-image: url("img/modal-lower-brush.png"); }

.modal__close {
  position: absolute;
  top: 30px;
  right: 30px; }

.is-visible.modal {
  visibility: visible; }
  .is-visible.modal .modal__inner {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
.is-visible ~ .modal-overlay {
  opacity: 1;
  visibility: visible; }

.main-nav::after {
  clear: both;
  content: "";
  display: table; }
.main-nav:after {
  content: "";
  display: block;
  height: 2px;
  background-color: #b4532f; }
.main-nav .menu-item {
  float: left;
  display: block;
  width: 33.3%;
  height: 90px;
  padding: 20px 0;
  text-align: center;
  border-right: 1px dashed #8c8482;
  border-bottom: 1px dashed #8c8482; }
  .main-nav .menu-item:nth-child(3n) {
    margin-right: 0; }
  .main-nav .menu-item:nth-child(3n+1) {
    clear: left; }
  .main-nav .menu-item a {
    font-weight: 700;
    color: #bab5b4;
    text-transform: uppercase; }
    .main-nav .menu-item a .icon-caret-down {
      margin-left: 5px;
      position: relative;
      top: 2px; }
  .main-nav .menu-item:nth-child(n+4) {
    border-bottom: none; }
  .main-nav .menu-item:nth-child(3n) {
    border-right: none; }
  .main-nav .menu-item.current-menu-item a, .main-nav .menu-item.current-page-ancestor a {
    color: white; }
@media screen and (min-width: 53.75em) {
  .main-nav {
    max-width: 890px;
    margin: 0 auto; }
    .main-nav:after {
      content: none; }
    .main-nav .menu-item {
      width: 110px;
      padding: 0;
      height: 60px;
      border-left: 1px dashed #8c8482;
      border-right: 1px dashed #8c8482;
      border-bottom: none;
      margin-right: -1px;
      margin-top: 14px;
      margin-bottom: 14px; }
      .main-nav .menu-item:nth-child(3n) {
        margin-right: 2.35765%; }
      .main-nav .menu-item:nth-child(3n+1) {
        clear: none; }
      .main-nav .menu-item:nth-child(3n) {
        margin-right: 0;
        border-right: 1px dashed #8c8482; }
      .main-nav .menu-item.menu-item-has-children {
        margin-right: 233px; }
      .main-nav .menu-item:hover > a {
        color: white; } }
@media screen and (min-width: 1280px) {
  .main-nav {
    max-width: 1028px; }
    .main-nav .menu-item {
      width: 120px; }
      .main-nav .menu-item.menu-item-has-children {
        margin-right: 310px; } }
.ie8 .main-nav:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }
.ie8 .main-nav .menu-item {
  width: 107px; }

.submenu {
  background-color: white;
  border: 1px solid #e8e6e6;
  position: absolute;
  top: 90px;
  right: 17px;
  width: 190px;
  z-index: 10; }
  .submenu:before {
    content: "";
    height: 0;
    width: 0;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: -10px;
    right: 30px; }
  .submenu .submenu-item {
    text-align: center;
    border-bottom: 1px dashed #8c8482; }
    .submenu .submenu-item a {
      display: block;
      padding: 12px 0;
      color: #b4532f;
      text-transform: uppercase; }
    .submenu .submenu-item:last-child {
      border-bottom: none; }
  @media screen and (min-width: 40em) {
    .submenu {
      right: 35%; } }
  @media screen and (min-width: 53.75em) {
    .submenu {
      top: 70px;
      right: 17px; } }

.nav-icon-holder {
  height: 37px; }

.menu-item-has-children {
  position: relative; }

.nav-is-visible i {
  color: white; }

.page-intro {
  color: white;
  text-align: center;
  padding: 120px 15px 30px;
  background-color: #190a06;
  position: relative;
  z-index: 2; }
  .page-intro:after {
    content: '';
    display: block;
    height: 21px;
    width: 100%;
    background-image: url("img/page-intro-brush.png");
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0; }
    @media screen and (min-width: 53.75em) {
      .page-intro:after {
        height: 41px;
        background-image: url("img/page-intro-brush-large.png");
        background-size: contain; } }
  @media screen and (min-width: 53.75em) {
    .page-intro {
      padding-top: 150px; } }
  .page-intro .title span {
    display: inline-block;
    position: relative; }
    .page-intro .title span:before, .page-intro .title span:after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 1000px;
      background-color: white;
      position: absolute;
      top: 50%;
      margin-top: -2px; }
    .page-intro .title span:before {
      right: 100%;
      margin-right: 10px; }
    .page-intro .title span:after {
      left: 100%;
      margin-left: 10px; }

.page-intro__inner {
  overflow: hidden;
  max-width: 1028px;
  margin: 0 auto; }

.profile-image {
  width: 150px;
  margin: 0 auto; }

.sidebar h2 {
  font-family: "proxima-nova";
  font-size: 14px;
  font-weight: 700;
  color: #bab5b4;
  text-transform: uppercase;
  margin-bottom: 20px; }
@media screen and (min-width: 53.75em) {
  .sidebar {
    float: right;
    width: 38%; } }

.sidebar--light {
  background-color: white; }

.sidebar__inner {
  padding: 40px 15px; }
  @media screen and (min-width: 53.75em) {
    .sidebar__inner {
      padding-left: 70px;
      padding-right: 70px; } }

.tile {
  height: 140px;
  text-align: center;
  border-bottom: 1px dashed #190a06;
  overflow: hidden; }
  .tile a {
    display: block;
    height: 100%;
    padding: 25px 20px;
    position: relative;
    -webkit-transition: padding-top 0.3s;
    -moz-transition: padding-top 0.3s;
    transition: padding-top 0.3s; }
  @media screen and (min-width: 40em) {
    .tile {
      width: 50%;
      height: 160px;
      float: left;
      border-right: 1px dashed #190a06; }
      .tile:nth-child(2n) {
        margin-right: 0; }
      .tile:nth-child(2n+1) {
        clear: left; }
      .tile:nth-child(2n) {
        border-right: none; } }
  @media screen and (min-width: 53.75em) {
    .tile {
      width: 25%;
      height: 240px;
      float: left; }
      .tile:nth-child(2n+1) {
        clear: none; }
      .tile:nth-child(4n) {
        margin-right: 0; }
      .tile:nth-child(4n+1) {
        clear: left; }
      .tile:not(:nth-child(4n)) {
        border-right: 1px dashed #190a06; } }
  .ie8 .tile {
    width: 24.9%; }

.tile__image-holder {
  opacity: 0;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.5s, top 0.3s 0.05s;
  -moz-transition: opacity 0.5s, top 0.3s 0.05s;
  transition: opacity 0.5s, top 0.3s 0.05s; }

.tile:hover .tile__image-holder {
  opacity: 1;
  top: 0; }
  .tile:hover .tile__image-holder:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2); }
.tile:hover a {
  padding-top: 50px; }
.tile:hover .tile__pretitle span {
  color: white; }
  .tile:hover .tile__pretitle span:before, .tile:hover .tile__pretitle span:after {
    background-color: white; }

.tile__copy {
  position: relative;
  z-index: 2; }

.tile__pretitle {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px; }
  .tile__pretitle span {
    display: inline-block;
    position: relative; }
    .tile__pretitle span:before, .tile__pretitle span:after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 40px;
      background-color: #190a06;
      position: absolute;
      top: 50%;
      margin-top: -2px; }
    .tile__pretitle span:before {
      right: 100%;
      margin-right: 10px; }
    .tile__pretitle span:after {
      left: 100%;
      margin-left: 10px; }
  @media screen and (min-width: 53.75em) {
    .tile__pretitle {
      font-size: 18px; } }

.tile__title {
  font-size: 24px;
  color: white;
  margin-bottom: 0; }
  @media screen and (min-width: 40em) {
    .tile__title {
      font-size: 30px; } }
  @media screen and (min-width: 53.75em) {
    .tile__title {
      font-size: 36px; } }



.page-intro {
	color: white;
	text-align: center;
	padding: 120px 15px 30px;
	background-color: $dark-brown;
	position: relative;
	z-index: 2;

	&:after {
		content: '';
		display: block;
		@include size(100% 21px);
		background-image: url('img/page-intro-brush.png');
		@include position(absolute, null 0 -20px 0);

		@include media($large-screen-up) {
			height: 41px;
			background-image: url('img/page-intro-brush-large.png');
			background-size: contain;
		}
	}

	@include media($large-screen-up) {
		padding-top: 150px;
	}

	.title {
		span {
			@include dashes(1000px, 1px, white);
		}
	}
}

.page-intro__inner {
	overflow: hidden;
	max-width: 1028px;
	margin: 0 auto;
}

.profile-image {
	width: 150px;
	margin: 0 auto;
}
.site-header {
  background-color: $dark-brown;
}

.site-header__actions {
  position: relative;
  padding: 16px 15px;
  @include clearfix;
}

.hamburger,
.login {
  font-size: 26px;
  line-height: 0;
}

.hamburger {
  float: left;
  color: $light-brown;

  @include media($large-screen-up) {
    display: none;
  }
}

.login {
  float: right;
  color: $medium-gray;

  &:visited,
  &:focus {
    color: $medium-gray;
  }

  &.is-active {
    color: white;
  }

  .ie8 & {
    @include position(absolute, 10px 10px null null);
  }
}

.logo {
  display: block;
  width: 116px;
  @include position(absolute, null null null 50%);
  z-index: 3;
  margin-left: -58px;

  @include media($large-screen-up) {
    width: 198px;
    margin-left: -(198px / 2);
  }

  .ie8 & {
    margin-top: -90px;
  }
}
.featured {
  min-height: 380px;
  color: white;
  text-align: center;
  text-shadow: $featured-text-shadow;
  padding-top: 145px;
  background-size: cover;
  background-position: 50%;

  @include media($large-screen-up) {
    min-height: 550px;
    padding-top: 160px;
  }
}

.pretitle {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;

  span {
    @include dashes(40px, 1px, white);
  }
}

.title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;

  @include media($medium-screen-up) {
    font-size: 52px;
  }

  @include media($large-screen-up) {
    font-size: 76px;
  }
}

.title--spots {
  position: relative;

  &:before {
    content: '';
    display: block;
    background-image: url('img/title-spots-overlay.png');
    @include position(absolute, 0 0 0 0);
    z-index: 1;
  }
}

.featured__metas {
  font-size: 14px;
  font-weight: 700;
}